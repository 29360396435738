var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c("br"),
          _c("div", [
            _c("h5", [_vm._v("Online Sites")]),
            _c("div", { staticClass: "small" }, [
              _vm._v(" List of paused sites, which are potentially back "),
              _c("b", [_vm._v("Online")]),
              _vm._v(". "),
            ]),
          ]),
          _c("br"),
          _c("br"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _vm.loading
                    ? _c(
                        "h4",
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "" },
                          }),
                        ],
                        1
                      )
                    : _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.site_data,
                          "items-per-page": 5,
                          "item-key": "id",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.nativePlatformLinkIconPlaceholder",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            return [
                                              item.monitoring_url
                                                ? _c(
                                                    "img",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          src: require("@/assets/img/" +
                                                            item.monitoring_system +
                                                            ".png"),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return _vm.openWindow(
                                                              item.monitoring_url
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    )
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Monitoring Link")])]
                                ),
                                [
                                  !item.monitoring_url
                                    ? _c(
                                        "v-icon",
                                        { attrs: { disabled: "" } },
                                        [_vm._v("mdi-web-box")]
                                      )
                                    : _vm._e(),
                                ],
                              ]
                            },
                          },
                          {
                            key: "item.addNoteIconPlaceholder",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openNotes(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [_vm._v("mdi-note-plus")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("View Notes")])]
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.unpausePlaceholder",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2",
                                    attrs: { icon: "", color: "green" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.unpauseSite(item)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-play-outline")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "50%" },
          model: {
            value: _vm.showNotesModal,
            callback: function ($$v) {
              _vm.showNotesModal = $$v
            },
            expression: "showNotesModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Site Notes ")]),
              _c(
                "v-card-text",
                [
                  [
                    _c("div", { staticClass: "container" }, [
                      _c(
                        "div",
                        { staticClass: "row mb-3" },
                        [
                          [
                            _c(
                              "form",
                              {
                                staticClass: "col-12",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addNote()
                                  },
                                },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: { label: "Create a new note..." },
                                  model: {
                                    value: _vm.newNote,
                                    callback: function ($$v) {
                                      _vm.newNote = $$v
                                    },
                                    expression: "newNote",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "ul",
                            {
                              staticClass: "list-group",
                              staticStyle: { "padding-left": "0px" },
                            },
                            _vm._l(_vm.notes, function (note) {
                              return _c("note", {
                                attrs: {
                                  description: note.note,
                                  date: note.date,
                                  id: note.id,
                                },
                                on: {
                                  "on-toggle": function ($event) {
                                    return _vm.toggleNote(note)
                                  },
                                  "on-delete": function ($event) {
                                    return _vm.deleteNote(note)
                                  },
                                  "on-edit": function ($event) {
                                    return _vm.editNote(note, $event)
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n            font-weight-bold\n            text-xs\n            btn-default\n            bg-gradient-danger\n            shadow-danger\n          ",
                      attrs: { ripple: false, elevation: 0 },
                      on: {
                        click: function ($event) {
                          return _vm.closeNotes()
                        },
                      },
                    },
                    [_vm._v("Close Notes")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }